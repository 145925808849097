import { useLayoutEffect, useState } from 'react';
import { z } from 'zod';
import { overrideExperimentVariant } from '../../client-side-enrollment/enroll';
import { type Experiments, type ExperimentVariants } from '../../experiments';
import { useExperimentEnrollmentCookie } from '../../hooks/use-client-side-presignup-experiment';

export function ExperimentOverrides<T extends Experiments>() {
  const [cookieEnrollments, setEnrollmentValues] =
    useExperimentEnrollmentCookie();

  const [overrides] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlOverrides: Partial<Record<T, ExperimentVariants<T>>> = {};
    for (const [key, value] of urlParams) {
      if (key.startsWith('exp_') === true) {
        const { success, data } = z
          .number()
          .safeParse(parseInt(key.replace('exp_', ''), 10));
        if (success === false) {
          continue;
        }

        const parsedValue = z.number().safeParse(parseInt(value, 10));
        if (parsedValue.success === false) {
          continue;
        }

        urlOverrides[data as T] = parsedValue.data as ExperimentVariants<T>;
      }
    }

    return urlOverrides;
  });

  useLayoutEffect(() => {
    overrideExperimentVariant({
      cookieVariants: cookieEnrollments,
      overrides: Object.entries(overrides).map(([key, variant]) => {
        return {
          expId: parseFloat(key),
          variant: variant as number,
        };
      }),
      setCookie: setEnrollmentValues,
    });
  }, [overrides, cookieEnrollments, setEnrollmentValues]);

  return null;
}
